import React from 'react'
import InsetTitle from './inset-title'

export default function Services({services}) {
    return (
        <div className='grid grid-cols-2 bt1 pt-[40px] mb-[140px] gap-x-[140px] md:pt-[30px] md:grid-cols-1 md:mb-[90px]'>
            <InsetTitle title='SERVICES' content={services.title} />
            <div className='pt-[20px] md:pt-[90px]'>
                <div className='mb-[60px] md:mb-[90px]'>
                    <h2 className='h4 mb-[30px]'>PROJECT PLANNING AND FACILITATION</h2>
                    {services.planning.map(({content}) =>{
                        return <p className='p'>{content}</p>
                    })}
                 
                </div>
                <div className=''>
                    <h2 className='h4 mb-[30px]'>PROJECT DELIVERY</h2>
                    {services.delivery.map(({content}) =>{
                        return <p className='p'>{content}</p>
                    })}
                </div>
            </div>
        </div>
    )
}
