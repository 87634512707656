import styled from "styled-components"

const ImageZoom = styled.div`
         padding-top:${props => props.ratio };
        position:relative ;
        overflow:hidden ;
        ${props => props.wrapper}:hover & {
            & > div{
                transform:scale(1.05);
            }
        }
        & > div{
            transition: 1s ease transform ;
            position:absolute ;
            top:0px;
            height:100% ;
        }
        @media(pointer: coarse),(max-width:768px){
            ${props => props.mobile}
        }
`

export default ImageZoom