import React, {useMemo} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import { ContentContainer } from '../components/styles'
import FeaturedProject from "../components/featuredProject"
import Projects from "../components/projects"
import Quote from "../components/quote"
import Services from "../components/services"
import Apporach from "../components/apporach"
import Cta from "../components/cta"
import HomeFooter from "../components/home-footer"
import About from "../components/about"
import MoveUp from "../components/move-up"
import FadeIn from "../components/fade-in"



const IndexPage = ({ data: { home, projects } }) => {

  const reflection = {
    quote: home.clientQuote,
    client: home.clientName,
    image: home.clientImage
  }

  const services = {
    title: home.servicesTitle,
    delivery: home.projectDelivery,
    planning: home.projectPlanning
  }

  const apporach = {
    title: home.approachTitle,
    image: home.approachImage,
    content: home.approachContent
  }


  const filteredProjects = useMemo(() =>{
   return  projects.nodes.filter((project) => project.name !==  home.featuredProject.name)
  },[projects,home.featuredProject.name])

  return (
    <Layout>
      <Seo title="Home" />
      <Hero image={home.heroBanner} >
        <MoveUp />
      </Hero>

      <ContentContainer className="pt-[50px]">
        <FadeIn>
          <About title={home.aboutUsTitle} content={home.aboutUsContent} />
        </FadeIn>
        <FadeIn>
          <FeaturedProject project={home.featuredProject} className='pb-[80px] bt1 md:pb-[30px]' />
        </FadeIn>

          <Projects projects={filteredProjects}  />
      
      </ContentContainer>

      <Quote reflection={reflection} />
      <ContentContainer>
        <FadeIn>
          <Services services={services} />
        </FadeIn>
        <FadeIn>
          <Apporach apporach={apporach} />
        </FadeIn>
        <FadeIn>
          <Cta />
        </FadeIn>
      </ContentContainer>
      <HomeFooter />
    </Layout>
  )
}

export default IndexPage


export const query = graphql`
  query Homepage{
    home:datoCmsHomePage{
    
      heroBanner{
        gatsbyImageData
      }
      aboutUsTitle
      aboutUsContent {
          value
      }
      featuredProject {
        name
        date
        category
        slug
        featuredImage {
          gatsbyImageData
        }
      }
      clientName
      clientQuote
      clientImage{
        gatsbyImageData
      }
      servicesTitle
      projectDelivery {
        content
      }
      projectPlanning {
        content
      }
      approachTitle
      approachContent {
        value
      }
      approachImage {
        gatsbyImageData
      }
    }
    projects:allDatoCmsProject(sort: {order: ASC, fields: position}) {
      nodes {
        position
        id
        name
        slug
        date
        category
        featuredImage {
          gatsbyImageData
        }
       
      }
    }
  }

`

