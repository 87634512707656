import React from 'react'

export default function InsetTitle({ title, content, contentClass }) {
  return (
    <>
    <div className={("h4 pr-[40px] min-w-[110px] hidden md:block md:mb-[100px] md:text-black   " + (title ? '' :'md:hidden'))} >{title}</div>
    <h4 className={"h2 max-w-[700px] " + contentClass}>
      <div className="h4 pr-[40px] min-w-[110px] inline-block md:hidden">{title}</div>
      {content}
    </h4>
    </>
  )
}
