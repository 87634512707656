import React from 'react'

export function ProjectHeader({ project,className,...props }) {
    return (
        <div className={'hidden md:block ' + className}  {...props}>
            <h4 className='h3  '>{project.name}</h4>
            <div className='flex mb-[30px]'>
                <div>
                    <h4 className='h4'>{project.date}</h4>
                </div>
                <div className='hidden md:block ml-[10px] mr-[10px]'>
                    <h4 className='h4'>|</h4></div>
                <div><h4 className='h4'>{project.category}</h4></div>
                <div className='hidden md:flex flex-grow justify-end'>
                    <h4 className='h4'>View</h4>
                </div>
            </div>
        </div>
    )
}
