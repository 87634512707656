import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { css } from 'styled-components'
import Dot from './dot'
import Link from './link'
import { ProjectHeader } from './shared/project-thumbnail'
import ImageZoom from './image-zoom'

const Wrapper = styled.div`
    display:grid ;
    grid-template-columns:3fr 6fr;
    padding-top:40px;
    @media(pointer: coarse),(max-width:768px){
        padding-top:30px;
        grid-template-columns:1fr;
    }
`
const mobile = css`
    padding-top:125%;
`

export default function FeaturedProject({ project, ...props }) {




    return (
        <Link to={`/projects/${project.slug}`}>
            <Wrapper {...props}>
               
                <div className=''>
                    <h4 className='h4 md:text-black md:mb-[95px]'>Featured project</h4>
                    <h4 className='h3 md:hidden'>{project.name}</h4>
                </div>
                <ProjectHeader project={project} />
                <div className=''>
                    <div className='grid grid-cols-3 pb-[30px] md:hidden'>
                        <div><h4 className='h4'>{project.date}</h4></div>
                        <div className='hidden md:block ml-[10px] mr-[10px]'><h4 className='h4'>|</h4></div>
                        <div><h4 className='h4'>{project.category}</h4></div>
                        <div className='flex justify-end'>
                            <Dot wrapper={Wrapper} />
                        </div>
                        <div className='hidden md:flex flex-grow justify-end'>
                            <h4 className='h4'>View</h4>
                        </div>
                    </div>
                    <ImageZoom ratio='75% ' wrapper={Wrapper} mobile={mobile}>
                        <GatsbyImage class='' image={project.featuredImage.gatsbyImageData} />
                    </ImageZoom>
                </div>
            </Wrapper>
        </Link>
    )
}
