import React, { useContext } from 'react'
import Button from './button'

import {MenuContext} from '../context/menu-provider'
export default function Cta() {
  const {setOpen} = useContext(MenuContext)
  return (
    <div className='pt-[220px] pb-[220px] md:pt-[150px] md:pb-[150px]  '>
        <h2 className='h2 text-center mb-[30px] md:max-w-[350px] m-[auto]  '>Do you have a project that needs management?</h2>
        <div className="flex justify-center">
        <Button onClick={() => setOpen(true)} dark={true} text='Get in touch'/>  
        </div>
    </div>
  )
}
