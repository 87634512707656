import React from 'react'
import styled from 'styled-components'

const Groundcrew = styled.h4`
  font-size:12px;
  a{
    position:relative;
    &:after{
      content:'';
      width:0%;
      transition:0.5s ease width ;
      height:1px;
      position:absolute;
      bottom:-2px;
      background:white;
      left:0px;
    }
  }
  &:hover{
    a{
      &:after{
        width:100%;
      }
    }
  }
`

export function Contact() {
  return (
    <p className='small-p'>
      204 Latrobe Terrace,
      <br />
      Paddington QLD 4064 <br />
      <br />

      +61 421 838 343 <br />
      david@formpm.com.au

      <br />
      <br />
      QBCC Licence #15180713
    </p>
  )
}


export function CreatedBy({ ...props }) {

  return (
    <div  {...props} className='small-p'>
      <Groundcrew >Site By <a target="_blank" href='https://groundcrew.com.au/' rel="noreferrer" >Groundcrew</a></Groundcrew>
    </div>
  )
}

