import { GatsbyImage } from 'gatsby-plugin-image'
import React, { memo } from 'react'
import styled from 'styled-components'
import { ContentContainer } from './styles'

function Quote({reflection,...props}) {

    const Wrapper = styled.div`
        padding-top:200px;
        padding-bottom:200px;
        width:100%;
        position:relative;

    `
    return (
        <Wrapper className='mb-[60px]' {...props}>
            <ContentContainer className='z-20 relative flex justify-center  '>
                <div className='max-w-[900px]'>
                <h2 className='h2 white text-center mb-[60px] '>“{reflection.quote}”</h2>
                <h4 className='h4 text-white white text-center  '>{reflection.client}</h4>
                </div>
            </ContentContainer>
            <div className='absolute top-0 h-[100%] w-[100%] z-10'>
                <GatsbyImage image={reflection.image.gatsbyImageData}   className='absolute top-0 h-[100%] w-[100%] z-10 brightness-[.60]'/>
                </div>
           
        </Wrapper>
    )
}

export default memo(Quote, () => true)
