import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    opacity:0 ;
    transition: 1s ease opacity;
    ${props => props.wrapper}:hover & {
        opacity: 1;
    }
`
export default function Dot({...props}) {

    return (
        <Wrapper {...props}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#3D3D3D" />
        </svg>
        </Wrapper>

    )
}
