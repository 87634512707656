import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { StructuredText } from 'react-datocms'
import InsetTitle from './inset-title'

export default function Apporach({apporach}) {

    return (
        <div className='grid grid-cols-2 bt1 pt-[40px] gap-x-[40px] md:pt-[30px] md:grid-cols-1'>
            <div className='flex flex-col justify-between'>
                <InsetTitle title='APPROACH' content={apporach.title} />
                <div className=' mt-[30px] md:mt-[60px]'>
                    <div className='hidden md:block mb-[60px] '>
                    <GatsbyImage   image={apporach.image.gatsbyImageData} />  
                    </div>
                    <div className='p strut-text max-w-[500px]'>
                        <StructuredText data={apporach.content}/>
                    </div>
                </div>
            </div>
            <div className='md:hidden' >
            <GatsbyImage  image={apporach.image.gatsbyImageData} />  
            </div>
        </div>
    )
}
