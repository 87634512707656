import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { MenuContext } from '../context/menu-provider'

export default function Hero({ image, children }) {
  const [ref, inView] = useInView({ threshold:0.05 })
  const { setDark } = useContext(MenuContext)

  useEffect(() => {
    if (!inView) {
      return setDark(true)
    }
    return setDark(false)
  }, [inView,setDark])

  return (
    <div ref={ref} className='h-[100vh] relative ' >
      <div className='absolute z-10 h-[100%] w-[100%] opacity-95'>
        <GatsbyImage image={image?.gatsbyImageData} className='h-[100%] w-[100%] bg-[black] brightness-[.80] ' src='../images/hero.png' />
      </div>
      <div className='pb-[50px] pl-[60px] md:pl-[30px] md:pb-[80px] z-20 h-[100%] w-[100%] relative flex flex-col justify-end'>
        {children}
      </div>
    </div>
  )
}
