import React, { useContext, useEffect } from 'react'
import Logo from './icons/logo'
import Button from './button'
import styled from 'styled-components'
import { MenuContext } from '../context/menu-provider'
import { CreatedBy, Contact } from './shared/footer'
import { useInView } from 'react-intersection-observer'

export default function HomeFooter() {

  const { setOpen, setHide } = useContext(MenuContext)

  const { ref, inView } = useInView({ threshold: 0.5 })


  useEffect(() => {
    if (inView) {
      setHide(true)
    } else {
      setHide(false)
    }
  }, [inView])

  return (
    <div ref={ref} className='p-[60px] bg-[#3D3D3D] flex flex-col md:p-[30px] '>
      <div className=' grid-cols-2 grid   mb-[285px] md:grid-cols-1'>
        <Logo />
        <div className='md:mt-[60px] '>
          <h2 className='h2 white md:mb-[30px] '>Always exceptional</h2>
          <div className='hidden md:block'>
            <Button onClick={() => setOpen(true)} text='Get in touch' />
          </div>
        </div>
      </div>
      <div className='grid grid-cols-2 md:grid-cols-1'>
        <Contact />
        <div className='flex justify-between items-end  md:flex-col md:items-start md:pt-[30px] '>
          <CreatedBy />
          <div className='md:hidden'>
            <Button onClick={() => setOpen(true)} text='Get in touch' />
          </div>
        </div>
      </div>
    </div>
  )
}
