import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

const AnimationWrapper = styled.div`
  height:41px;
  overflow:hidden ;
  display:flex ;
 
`


const translate = keyframes`
  0% {
    transform: translateY(0%);
  }
  15% {
    transform: translateY(0%);
  }
  30% {
    transform: translateY(33.33%);
  }
  45% {
    transform: translateY(33.33%);
  }
  60% {
    transform: translateY(66.66%);
  }
  75% {
    transform: translateY(66.66%);
  }
  90% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(100%);
  }
`;

const InnerWrapper = styled.div`
    animation:${translate} 3s ease infinite;
`


export default function MoveUp() {


    return (
        <AnimationWrapper length={3}>
            <h4 className="h2 white mr-[10px] h2-desktop">Always: </h4>

            <div className='flex flex-col justify-end'>

                <InnerWrapper >
                    <h4 className="h2 white h2-desktop">on time </h4>
                    <h4 className="h2 white h2-desktop">on budget </h4>
                    <h4 className="h2 white h2-desktop">exceptional  </h4>
                </InnerWrapper>
                <InnerWrapper>
                    <h4 className="h2 white h2-desktop">on time </h4>
                    <h4 className="h2 white h2-desktop">on budget </h4>
                    <h4 className="h2 white h2-desktop">exceptional  </h4>
                </InnerWrapper>


            </div>
        </AnimationWrapper>)
}



