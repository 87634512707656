import React, { useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components';

const Wrapper = styled.div`
    transition:1s ease opacity ;
    opacity:${props => props.inView ? 1 : 0};
`


export default function FadeIn({ children }) {

    const { ref, inView } = useInView(0.5);
    const [fade, setFade] = useState(false)

    const fadedIn = useMemo(() => {
            if (inView && !fade) {
                setFade(true)
                return true
            }

            if(fade){
                return true
            }
    }, [inView])

    return (
        <Wrapper ref={ref} inView={fadedIn}>
            {children}
        </Wrapper>
    )
}
