import React from 'react'
import { StructuredText } from 'react-datocms'
import InsetTitle from './inset-title'

export default function About({ title, content }) {
    return (
        <>
            <div className="mb-[170px] pt-[40px] bt1 md:pt-[30px] md:mb-[60px] ">
                <InsetTitle title='ABOUT US' content={title} />
            </div>
            <div className="pb-[60px]">
                <div className="p strut-text max-w-[450px]">
                    <StructuredText data={content} />
                </div>
            </div>
        </>
    )
}
